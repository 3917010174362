/* Reset default margin and padding */
.game-container {
  width: 80%;
  margin: auto;
  text-align: center;
  border: 2px solid #E219C0;
  padding: 20px;
  background-color: rgba(0, 0, 0, 0.8);
  box-shadow: 0 0 10px #E219C0;
  position: relative;
  display: flex;
  flex-direction: column; /* Align items in a column */
  justify-content: space-between;
}

/* Set the dark background color */
body {
  background-color: #2c2c2c;
  color: #fff;
  font-family: Arial, sans-serif;
}

.timer {
  font-size: 18px;
}

/* Chat interface styles */
.chat-interface {
  flex: 1;
  background-color: rgba(255, 255, 255, 0.1);
  padding: 20px;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
}

.user {
  background-color: #007bff;
  text-align: right;
}

/* User input styles */
input[type="text"] {
  padding: 10px;
  margin-top: 10px;
  border: none;
  border-radius: 5px;
  margin: 5px;
  font-size: 16px;
  width: 80%;
  max-width: 400px;
  border: 2px solid #E219C0;
  border-radius: 5px;
}

button {
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 5px;
  padding: 10px;
  margin-top: 10px;
  cursor: pointer;
  width: 20%;
}
.interrogate-button {
  align-self: center;
}

/* Game information styles */
.game-info {
  flex: 1;
  background-color: rgba(255, 255, 255, 0.1);
  padding: 20px;
  border-radius: 10px;
}

/* Share and Leaderboard buttons styles */
.share-button,
.leaderboard-button {
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 5px;
  padding: 10px;
  margin-top: 10px;
  cursor: pointer;
}

h1 {
  display: inline-flex;
  align-items: center;
  color: #E219C0;
  font-size: 24px;
}

.alert-container {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  color: #E219C0;
  display: none;
  cursor: pointer;
}

.alert-container.show {
  display: block;
}

.objective {
  margin-top: 20px;
  background-color: rgba(255, 255, 255, 0.1);
  padding: 10px;
  border-radius: 5px;
  text-align: left;
  margin-bottom: 20px;
}

.max-w-2xl {
  max-width: 42rem;
}

.rounded-xl {
  border-radius: 0.75rem;
}

/* CSS for the parent container holding both sections */
.container-with-gap {
  display: flex;
  justify-content: space-between; /* To create space between the sections */
  gap: 20px; /* Adjust the gap as needed */
}

@media (max-width: 500px) {
  .container-with-gap {
    flex-direction: column; /* Stack sections vertically */
    align-items: center; /* Center-align sections horizontally */
  }
}

.key {
  font-weight: bold;
  margin-right: 8px; /* Add spacing between key and value if desired */
}

.example {
  margin: 20px 0;
  margin-bottom: 20px;
  padding: 30px 50px;
  text-align: center;
  background: rgba(0, 0, 0, 0.05);
  border-radius: 4px;
}

/* Additional styles for the container if needed */
.key-value-pairs {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 0.5rem; /* Add a 5px gap between elements */
  text-align: left;
}

.score-timer-level {
  display: flex;
  align-items: center; /* Vertically center the content */
  margin-top: 20px;
  background-color: rgba(255, 255, 255, 0.1);
  padding: 10px;
  border-radius: 5px;
  margin-bottom: 20px;
  justify-content: space-between; /* Distribute elements evenly along the row */
}

.name-input-container {
  margin-top: 20px;
  width: 250px;
  flex-direction: column;
  align-self: center;
}

@media (max-width: 500px) {
  .name-input-container {
    flex-direction: column; /* Stack sections vertically */
    align-items: center; /* Center-align sections horizontally */
    min-width: 250px;
  }
}

.response-input-container {
  margin-top: 20px;
}


.centered-header {
  text-align: center;
}

.table-column {
  text-align: center;
  
}


.leaderboard {
  flex: 1;
  background-color: rgba(255, 255, 255, 0.1);
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  margin-top: 20px;
  padding-bottom: 20px
}

.blinking {
  animation: blink-animation 1s step-end infinite;
}

@keyframes blink-animation {
  0%, 49.9%, 100% {
    opacity: 1;
  }
  50%, 99.9% {
    opacity: 0;
  }
}

/* CSS for the player info */
.player-info {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
  border-radius: 5px;
}

/* CSS for player name */
.player-name {
  font-weight: bold;
  margin-right: 8px;
}

/* CSS for player score */
.player-score {
  color: #ffd700; /* You can adjust the color as needed */
}

/* Media query for screens with a maximum width of 768px (mobile) */
@media (max-width: 768px) {
  .game-container {
    width: 95%; /* Adjust the width for mobile screens */
    padding: 5px;
  }

  /* Adjust other styles for mobile here */
  .chat-interface {
    padding: 10px;
  }

  input[type="text"] {
    font-size: 14px;
    width: auto /* Full width input for mobile */
  }

  button {
    width: 30%;
    font-size: 14px;
  }

  .container-with-gap {
    display: flex;
    flex-direction: column;
    
  }

  .max-w-2xl {
    max-width: 100%;
  }
  .rounded-xl {
    border-radius: 0;
  }
}
